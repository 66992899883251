import React, { useEffect, useState } from 'react';
import { Container, Grid, Header, Card, Icon, Dimmer, Loader, Table, Message } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { iipeerpapi } from '../misc/iipeerpapi';
import datetimeutils from '../utils/DateTimeUtils';
import Topbar from './Topbar'

const RecurringStatus = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const { NewmerchantTxnNo } = props
  // Retrieve query parameters
  // const merchantTxnNo = queryParams.get('merchantTxnNo');

  // const { NewmerchantTxnNo } = location.state || {};


  const merchantTxnNo = NewmerchantTxnNo;
  // console.log("recurring1"+NewmerchantTxnNo);
  // console.log("recurring2"+merchantTxnNo);
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mandateStatus, setMandateStatus] = useState(null);
  const [mandateMessages, setMandateMessage] = useState(null);
  const [errorList, setErrorList] = useState(null);
  const [formattedText, setFormattedText] = useState(null);


  useEffect(() => {
    const getData = async () => {
      try {
        const response = await iipeerpapi.getrecurring(merchantTxnNo);
        setApiData(response.data);
        const response_data = JSON.parse(response.data.response);
        
        const { STATUS, MESSAGE, ERROR_LIST, ERRORCODE } = response_data;
        //console.log(MESSAGE)
        if (response.data.responsestatus.includes('200 OK') && STATUS === 'True') {
          setMandateStatus(STATUS);
          setMandateMessage(MESSAGE);
        } else if (response.data.responsestatus.includes('200 OK') && STATUS === 'False' && ERROR_LIST) {
          setMandateStatus(STATUS);
          setMandateMessage(MESSAGE+" because of below reasons:");
          const errorMessages = Object.entries(response_data.ERROR_LIST).map(([key, value], index) => (
            ` ${keyToTextMap[key] || key}: "${value}"`
          ));

          setErrorList(errorMessages); // Make sure setErrorList is a state update function
          //console.log(errorMessages);
        }
        else if (response.data.responsestatus.includes('200 OK') && STATUS === 'False' && ERROR_LIST==null){
          setMandateStatus(STATUS);
          setMandateMessage(MESSAGE);
        }


      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false, whether the request succeeds or fails
      }
    };

    getData();
  }, [merchantTxnNo]);

  const frequencyOptions = [
    { key: 'monthly', text: 'Monthly', value: 'MNTH' },
    { key: 'yearly', text: 'Yearly', value: 'YEAR' },
  ];

  const remainingOptions = [
    { key: 'MOBILENO', text: 'Mobile Number', value: 'MOBILENO' },
    { key: 'ACCOUNTNUMBER', text: 'Account Number', value: 'ACCOUNTNUMBER' },
    { key: 'AC_TYPE', text: 'Account Type', value: 'AC_TYPE' },
    { key: 'BANK_ID', text: 'Bank Name', value: 'BANK_ID' },
    { key: 'AMOUNT', text: 'Amount', value: 'AMOUNT' },
    { key: 'FREQUENCY', text: 'Fequency', value: 'FREQUENCY' },
    { key: 'START_DATE', text: 'Start Date', value: 'START_DATE' },
    { key: 'END_DATE', text: 'End Date', value: 'END_DATE' },
    { key: 'CUST_NAME', text: 'Customer Name', value: 'CUST_NAME' },
    { key: 'MAILID', text: 'Maild Id', value: 'MAILID' },
    { key: 'AUTH_TYPE', text: 'Mandate Verification Through', value: 'AUTH_TYPE' },

  ];

  const accountTypeOptions = [
    { key: 'savings', text: 'Savings Account', value: 'SB' },
    { key: 'current', text: 'Current Account', value: 'CA' },
    { key: 'cash_credit', text: 'Cash Credit/OD', value: 'CC' },
    { key: 'others', text: 'Others', value: 'O' },
  ];

  const mandateVerificationOptions = [
    { key: 'internet_banking', text: 'Internet Banking', value: 'NB' },
    { key: 'debit_card', text: 'Debit Card', value: 'DC' },
    { key: 'aadhar_number', text: 'Aadhar Number', value: 'ADH' },
  ];

  const allOptions = [
    ...frequencyOptions,
    ...accountTypeOptions,
    ...mandateVerificationOptions,
    ...remainingOptions
  ];

  // Create a mapping object for quick lookups
  const keyToTextMap = allOptions.reduce((acc, option) => {
    acc[option.value] = option.text;
    return acc;
  }, {});

  // Render payment details in a Semantic UI React table
  const renderPaymentDetails = () => (
    <Table celled striped compact textAlign="center" style={{ width: '50%', margin: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">Mandate Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell>{(apiData.name) ? apiData.name : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email</Table.Cell>
          <Table.Cell>{(apiData.email) ? apiData.email : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Phone Number</Table.Cell>
          <Table.Cell>{(apiData.phoneno) ? apiData.phoneno : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Amount (INR)</Table.Cell>
          <Table.Cell>{(apiData.amount) ? apiData.amount : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Frequency</Table.Cell>
          <Table.Cell>{(apiData.frequencydetail) ? apiData.frequencydetail : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Number of Payments</Table.Cell>
          <Table.Cell>{(apiData.noofpayments) ? apiData.noofpayments : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Start Date</Table.Cell>
          <Table.Cell>{(apiData.startdate) ? datetimeutils.formatDateToDate(apiData.startdate) : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>End Date</Table.Cell>
          <Table.Cell>{(apiData.enddate) ? datetimeutils.formatDateToDate(apiData.enddate) : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Bank Name</Table.Cell>
          <Table.Cell>{(apiData.bankname) ? apiData.bankname : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Account Number</Table.Cell>
          <Table.Cell>{(apiData.accountno) ? apiData.accountno : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Account Type</Table.Cell>
          <Table.Cell>{(apiData.accounttypedetail) ? apiData.accounttypedetail : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Mandate Verification Through</Table.Cell>
          <Table.Cell>{(apiData.verifytypedetail) ? apiData.verifytypedetail : 'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>IIPE Mandate Request ID</Table.Cell>
          <Table.Cell>{(apiData.requestid) ? apiData.requestid : 'NA'}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Mandate Request Date & Time</Table.Cell>
          <Table.Cell>{(apiData.createdtime) ? datetimeutils.formatDateTimeToDateTime(apiData.createdtime) + ' (IST)' : 'NA'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>

  );


  function convertToIST(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Months are 0-based in JavaScript Date objects
    const day = dateString.substring(6, 8);
    const hours = dateString.substring(8, 10);
    const minutes = dateString.substring(10, 12);
    const seconds = dateString.substring(12, 14);

    const utcDateObject = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
    utcDateObject.setHours(utcDateObject.getHours() + 5);
    utcDateObject.setMinutes(utcDateObject.getMinutes() + 30);
    // Convert to IST
    const istOptions = { timeZone: "Asia/Kolkata" };
    const istDateString = utcDateObject.toLocaleString("en-US", istOptions);

    return istDateString;
  }

  return (
    <>
      <Topbar />

      <Container style={{ marginTop: '20px', 'min-height': '75vh' }}>
        {loading ? (
          // Display spinner while loading
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        ) : (apiData && apiData.responsestatus && apiData.responsestatus.includes('200 OK') && mandateStatus === 'True') ? (
          // Display success content for response code 000
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <Card fluid color="green">
                  <Card.Content textAlign="center">
                    <Icon name="check circle" size="huge" color="green" />
                    <Header as="h3" style={{ fontSize: '1.5em' }}>
                      Mandate Request SMS is Successfully Sent To Your Phone Number {apiData.phoneno}
                    </Header>
                    <Card.Description style={{ fontSize: '1.2em' }}>
                      Please open the link received in SMS in any browser and complete the mandate with bank credentials!
                      {/* Your donation to the Indian Institute of Petroleum and Energy is successful!*/}
                      <br />
                      <br />
                      {/* <a href="https://iipe.ac.in/giving">Go To Giving Portal</a> */}
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>{renderPaymentDetails()}</Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (apiData && apiData.responsestatus!=null && apiData.responsestatus.includes('200 OK') && mandateStatus === 'False') ? (
          // Display failed content for response codes other than 000
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <Card fluid color="red">
                  <Card.Content textAlign="center">
                    <Icon name="exclamation circle" size="huge" color="red" />
                    <Header as="h2" style={{ fontSize: '2em' }}>
                      Mandate Request to Your Phone Number is Unsuccessful!
                    </Header>
                    <Card.Description style={{ fontSize: '1.2em' }}>
                      {'Please Contact IIPE Office!'}
                      <br />
                      <br />
                      <a href="/">Please Go to Donation Page To Try Again!</a>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Message error>
                <Message.Header>{mandateMessages}</Message.Header>
                <Message.List>
                  {errorList && errorList.map((message, index) => (
                    <Message.Item key={index}>{message}</Message.Item>
                  ))}
                </Message.List>
              </Message>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{renderPaymentDetails()}</Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
        // Display a message if no data is available
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={6}>
              <Card fluid color="green">
                <Card.Content textAlign="center">
                  <Icon name="exclamation circle" size="huge" color="red" />
                  <Header as="h2" style={{ fontSize: '2em' }}>
                    Data Unavailable
                  </Header>
                  <Card.Description style={{ fontSize: '1.2em' }}>
                    Data Is Not Available In Our Records For The Given Merchant ID
                    <br />
                    <br />
                    <a href="/">Please Launch The Donation Portal To Donate!</a>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        )}
      </Container>
    </>
  );
};

export default RecurringStatus;
