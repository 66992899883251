import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider } from './components/context/AuthContext'
import Footerbar from './components/misc/Footerbar'
import DonationTest from './components/home/DonationTest'
import PaymentStatus from './components/home/PaymentStatus'
import { ToastContainer, toast, Slide } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import RecurringStatus from './components/home/RecurringStatus'

function App() {

  return (

    <div className='App'>

      
        {/* <Navbar /> */}
        <Routes>
        
        {/* <Route path="/donatios/payments" element={<PaymentStatus />} /> */}
        {/* <Route path="/donatios/recurringstatus" element={<RecurringStatus />} /> */}
          <Route path="/payments/*" element={<PaymentStatus />} />
          <Route path="/recurringstatus/*" element={<RecurringStatus />} />
          {/* <Route path="/donate" element={<DonationTest />} /> */}
          {/* <Route path="/donation" element={<DonationTest />} /> */}
          <Route path="/" element={<DonationTest />} />
          <Route path="*" element={<DonationTest />} /> 
        </Routes>
        <Footerbar />
     
     <ToastContainer></ToastContainer>
        </div>
       
 
  )
}
export default App
