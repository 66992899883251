import React from 'react'
import { Link } from 'react-router-dom'
import { Grid,Container } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';

import { useAuth } from '../context/AuthContext'
import { Footer } from "semantic-ui-react";
import './footerbar.css'; 
function Footerbar() {
  const current = new Date()
  return (
    <div className="page-container">


    <div className="footer" >
      <Container textAlign="center">
        <h5>©{current.getFullYear()} Indian Institute of Petroleum and Energy (IIPE) , Visakhapatnam.   &nbsp; All Rights Reserved.  &nbsp; Designed & Developed by IT Software Team@IIPE</h5>
      </Container>
    </div>
  </div>
 
  );
}
export default Footerbar