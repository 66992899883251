import React, { useState, useRef, useEffect } from 'react';
import { Container, Grid, Header, Form, Button, Input, Dropdown } from 'semantic-ui-react';
import bankDetails from './js/getLiveBankDtls.json';
import { iipeerpapi } from '../misc/iipeerpapi'
import { NavLink, Navigate } from 'react-router-dom'
import { Component } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMonths, addYears, isValid } from 'date-fns';
import { ToastContainer, toast } from "react-toastify";
import Toasts from '../misc/Toasts';
import Topbar from './Topbar'
import { config } from '../../Constants'
import { useLocation, useNavigate } from 'react-router-dom';
import RecurringStatus from './RecurringStatus';
import PaymentStatus from './PaymentStatus'

const DonationTest = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Retrieve query parameters
  const NewmerchantTxnNo = queryParams.get('merchantTxnNo');
  const GiftType = queryParams.get('giftType');

  const apiurl = config.url.API_BASE_URL

  const [errorMessage, setErrorMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [giftAmount, setGiftAmount] = useState('');
  const [recGiftAmount, setRecurringGiftAmount] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const [email, setEmail] = useState('');
  const [selectedGiftType, setSelectedGiftType] = useState('ONE_TIME_GIFT');
  const [frequency, setFrequency] = useState('');
  const [noOfPayments, setNoOfPayments] = useState(2);
  const [bankName, setBankName] = useState('');
  const [frequencydetail, setfreqdetail] = useState('');
  const [accounttypedetail, setaccounttypedetail] = useState('');
  const [verifytypedetail, setverifytypedetail] = useState('');
  const [bankID, setBankID] = useState('');
  const [bankAccountNo, setBankAccountNo] = useState('');
  const [bankAccountType, setBankAccountType] = useState('');
  const [mandateVerification, setMandateVerification] = useState('');
  const [securehashing, setsecurehashing] = useState('');
  const [merchantTxnNo, setmerchantTxnNo] = useState('');
  const [returnURL, setReturnURL] = useState(apiurl + '/api/donations/paymentredirect');
  const submitButtonRef = useRef();
  const securedref = useRef();
  const txnref = useRef();
  const amountref = useRef();


  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState('');

  const handleDateChange = (event, { name, value }) => {
    if (name === 'startDate') {
      // Validate that the start date is not empty
      if (!value) {
        event.target.setCustomValidity('Please fill out this field!');
        setStartDate(value);
      } else {
        event.target.setCustomValidity(''); // Clear any previous custom validity
        setStartDate(value);
      }
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };

  const formatDate = (dateString) => {

    if (dateString == null || dateString === '') {
      return ''
    }
    else {
      const date = new Date(dateString);
      return format(date, 'dd-MM-yyyy');
    }
  };


  const handleClick = () => {
    // Programmatically click the submit button
    submitButtonRef.current.click();
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');

    // Limit to 13 digits
    if (value.length <= 13) {
      setPhoneNumber(value.trim());

      // Minimum 10 digits validation
      if (value.length < 10) {
        e.target.setCustomValidity('Minimum of 10 digits required for Phone Number');
      } else {
        e.target.setCustomValidity('');
      }
    }
  };

  const activeBanks = bankDetails.liveBankList.filter(bank => bank.activeFrm !== 'NULL');

  // Format bank options for Dropdown
  const bankOptions = activeBanks.map(bank => ({
    key: bank.bankId,
    text: bank.bankName,
    value: bank.bankId,
  }));

  const handleGiftAmountChange = (e) => {
    const value = e.target.value;
    setGiftAmount(value);

    // Show error if the gift amount is below 500
    if (parseInt(value, 10) < 500) {
      // e.target.setCustomValidity('Minimum gift amount: INR 500');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleRecurringGiftAmountChange = (e) => {
    const value = e.target.value;
    setRecurringGiftAmount(value);

    // Show error if the gift amount is below 500
    if (parseInt(value, 10) < 1) {
      e.target.setCustomValidity('Minimum gift amount: INR 1');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handlePostalAddressChange = (e) => {
    setPostalAddress(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFrequencyChange = (e, { value }) => {
    setFrequency(value);
    const selectedBank = frequencyOptions.find((bank) => bank.value === value);
    if (selectedBank) {
      setfreqdetail(selectedBank.text);
    }
  };

  const handleBankAccountTypeChange = (e, { value }) => {
    setBankAccountType(value);
    const selectedBank = accountTypeOptions.find((bank) => bank.value === value);
    if (selectedBank) {
      setaccounttypedetail(selectedBank.text);
    }
  };

  const handleMandateVerificationChange = (e, { value }) => {
    setMandateVerification(value);
    const selectedBank = mandateVerificationOptions.find((bank) => bank.value === value);
    if (selectedBank) {
      setverifytypedetail(selectedBank.text);
    }
  };

  const handleSubmit = () => {
    // Add your form submission logic here
    if (selectedGiftType === 'ONE_TIME_GIFT') {
      handleOneTimeGiftSubmit();
    } else if (selectedGiftType === 'RECURRING_GIFT') {
      handleRecurringGiftSubmit();
    }
  };

  const handleOneTimeGiftSubmit = () => {
    // Create the JSON payload for One Time Gift
    const oneTimeGiftPayload = {
      giftType: 'ONE_TIME_GIFT',
      giftAmount,
      name,
      country,
      postalAddress,
      email,
      phoneNumber,
      returnURL
    };

    iipeerpapi.submitonetimegift(oneTimeGiftPayload)
      .then(response => {
        const resp = response.data;

        setsecurehashing(resp.securedhash);
        setmerchantTxnNo(resp.transactionnumber);
        //this.handleResp(resp)

        securedref.current.value = resp.securedhash;
        txnref.current.value = resp.transactionnumber;
        amountref.current.value = giftAmount
        handleClick();
      })
      .catch(error => {
        Toasts.notifyError("Something Went Wrong! Please Contact IIPE Office!")
      })
    //console.log('One Time Gift Payload:', oneTimeGiftPayload);
    // Add logic to send the payload to the server
  };

  const handleRecurringGiftSubmit = () => {
    // Create the JSON payload for Recurring Gift
    const recurringGiftPayload = {
      giftType: 'RECURRING_GIFT',
      recGiftAmount,
      frequency,
      noOfPayments,
      bankName,
      bankID,
      bankAccountNo,
      bankAccountType,
      mandateVerification,
      country,
      postalAddress,
      email,
      phoneNumber,
      name,
      startDate,
      endDate,
      frequencydetail,
      accounttypedetail,
      verifytypedetail
      // Add other fields specific to Recurring Gift
    };
    iipeerpapi.submitrecurringgift(recurringGiftPayload)
      .then(response => {
        const resp = response.data;


        // Assuming the redirect URL is in the response, update accordingly
        const redirectUrl = resp.redirectUrl;

        // Redirect to the new URL
        window.location.href = redirectUrl;

      })
      .catch(error => {
        Toasts.notifyError("Something Went Wrong! Please Contact IIPE Office!")
        //console.log(resp);
      })


    // Add logic to send the payload to the server
  };

  const handleGiftTypeChange = (giftType) => {
    //setGiftAmount(''); // Reset gift amount when switching between forms
    setSelectedGiftType(giftType);
  };

  const calculateEndDate = () => {
    if (!startDate) return '';

    let endDate;

    if (frequency === 'monthly') {
      endDate = addMonths(new Date(startDate), noOfPayments);
    } else if (frequency === 'yearly') {
      endDate = addYears(new Date(startDate), noOfPayments);
    }

    return format(endDate, 'dd-MMM-yyyy');
  };



  const frequencyOptions = [
    { key: 'monthly', text: 'Monthly', value: 'MNTH' },
    { key: 'yearly', text: 'Yearly', value: 'YEAR' },
  ];

  const accountTypeOptions = [
    { key: 'savings', text: 'Savings Account', value: 'SB' },
    { key: 'current', text: 'Current Account', value: 'CA' },
    { key: 'cash_credit', text: 'Cash Credit/OD', value: 'CC' },
    { key: 'others', text: 'Others', value: 'O' },
  ];

  const mandateVerificationOptions = [
    { key: 'internet_banking', text: 'Internet Banking', value: 'NB' },
    { key: 'debit_card', text: 'Debit Card', value: 'DC' },
    { key: 'aadhar_number', text: 'Aadhar Number', value: 'ADH' },
  ];

  const allOptions = [
    ...frequencyOptions,
    ...accountTypeOptions,
    ...mandateVerificationOptions,
  ];
  const handleNoOfPaymentsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setNoOfPayments(value);

    if (value < 2) {
      e.target.setCustomValidity('Number of payments must be at least 2.');
    } else {
      e.target.setCustomValidity('');
    }
  };

  useEffect(() => {

    const calculateEndDate = () => {
      if (!startDate || !frequency || !noOfPayments || noOfPayments === '' || noOfPayments == null) return '';

      let calculatedEndDate;


      if (frequency === 'MNTH') {
        if (noOfPayments > 0) {
          calculatedEndDate = addMonths(new Date(startDate), noOfPayments);
        }
      } else if (frequency === 'YEAR') {
        if (noOfPayments > 0) {
          calculatedEndDate = addYears(new Date(startDate), noOfPayments);
        }
      }

      // Check if the calculatedEndDate is a valid date
      if (calculatedEndDate != null && isValid(calculatedEndDate)) {
        return format(calculatedEndDate, 'yyyy-MM-dd');
      } else {
        return '';
        //return 'Invalid Date';
      }
    };

    setEndDate(calculateEndDate());

    

  }, [startDate, frequency, noOfPayments,bankOptions,frequencyOptions,accountTypeOptions,mandateVerificationOptions]);
  
  useEffect(() => {
    // Update bank-related states only when these dependencies change
    const defaultBankOption = bankOptions[0];
    setBankID(defaultBankOption.value);
    setBankName(defaultBankOption.text);

    const defaultFrequencyOption = frequencyOptions[0];
    setFrequency(defaultFrequencyOption.value);
    setfreqdetail(defaultFrequencyOption.text);

    const defaultAccountTypeOption = accountTypeOptions[0];
    setBankAccountType(defaultAccountTypeOption.value);
    setaccounttypedetail(defaultAccountTypeOption.text);

    const defaultMandateVerificationOption = mandateVerificationOptions[0];
    setMandateVerification(defaultMandateVerificationOption.value);
    setverifytypedetail(defaultMandateVerificationOption.text);

  }, []);
  // if (GiftType==='ONETIMEGIFT') {

  //   navigate('/payments', { state: { NewmerchantTxnNo , } });
  //   return null;

  // }
  // if (GiftType==='RECURRINGGIFT') {

  //   navigate('/recurringstatus', { state: { NewmerchantTxnNo , } });
  //   return null;

  // }
  return (
    (GiftType === 'ONETIMEGIFT') ? <PaymentStatus NewmerchantTxnNo={NewmerchantTxnNo} /> : (GiftType === 'RECURRINGGIFT') ? <RecurringStatus NewmerchantTxnNo={NewmerchantTxnNo} /> :
      <>

        <Topbar />

        <Container style={{ width: '60%', maxWidth: '600px', border: '1px solid #ccc', padding: '20px', borderRadius: '8px', 'min-height': '75vh' }}>
          <Grid>
            <Grid.Row verticalAlign="middle" textAlign="center">
              <Grid.Column>
                <Header as="h1">Donate to IIPE Visakhapatnam</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="center">

                <Form.Field>
                  <label style={{ fontWeight: 'bold', fontSize: '1.5em', color: (selectedGiftType === 'ONE_TIME_GIFT') ? 'green' : 'purple' }}>Select Gift Type</label>
                </Form.Field>
                <br />
                <Button.Group>
                  <Button
                    color={selectedGiftType === 'ONE_TIME_GIFT' ? 'green' : null}
                    style={{ fontWeight: 'bold', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                    onClick={() => handleGiftTypeChange('ONE_TIME_GIFT')}
                  >
                    ONE TIME GIFT
                  </Button>
                  <Button.Or />
                  <Button
                    color={selectedGiftType === 'RECURRING_GIFT' ? 'purple' : null}
                    style={{ fontWeight: 'bold', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                    onClick={() => handleGiftTypeChange('RECURRING_GIFT')}
                  >
                    RECURRING GIFT
                  </Button>
                </Button.Group>
              </Grid.Column>
            </Grid.Row>

            {selectedGiftType === 'RECURRING_GIFT' ? (
              <Grid.Row>
                <Grid.Column>
                  {/* New Form for Recurring Gift */}
                  <Form onSubmit={handleSubmit}>
                    {/* New Fields for Recurring Gift */}
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Enter Gift Amount (INR)*</label>
                        <Input
                          placeholder="Enter Gift Amount"
                          type="number"
                          min="1"
                          value={recGiftAmount}
                          onChange={handleRecurringGiftAmountChange}
                          required
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Name *</label>
                        <Input placeholder="Name" value={name} onChange={handleNameChange} required />
                      </Form.Field>
                    </Form.Group>

                    {/* Existing Fields for Recurring Gift */}
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Frequency *</label>
                        <Dropdown
                          placeholder="Frequency"
                          fluid
                          selection
                          options={frequencyOptions}
                          onChange={handleFrequencyChange}
                          defaultValue={frequencyOptions[0].value}
                          required
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>No Of Payments *</label>
                        <Input
                          placeholder="No Of Payments"
                          type="number"
                          min="2"
                          value={noOfPayments}
                          onChange={handleNoOfPaymentsChange}
                          required
                          onInvalid={(e) => e.target.setCustomValidity('Number of payments must be at least 2.')}
                          onInput={(e) => e.target.setCustomValidity('')}
                        />
                      </Form.Field>
                    </Form.Group>
                    {/* Additional Fields */}

                    {/* Remaining Fields for Recurring Gift */}
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Start Date *</label>
                        <Input
                          type="date"
                          name="startDate"
                          placeholder="Date"
                          value={startDate}
                          // iconPosition="left"
                          onChange={handleDateChange}
                          min={format(new Date(), 'yyyy-MM-dd')}
                          required

                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>End Date *</label>
                        <div
                          style={{
                            backgroundColor: '#f0f0f0',  // Example background color for disabled effect
                            border: '1px solid #d9d9d9', // Example border color for disabled effect
                            color: 'black',              // Text color
                            padding: '8px',              // Example padding for better appearance
                            whiteSpace: 'pre',
                          }}
                        >
                          {(noOfPayments > 0) ? formatDate(endDate) : ' '}
                        </div>

                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={8} inline >
                        <label>Bank Name *</label>
                        <Dropdown
                          placeholder="Bank Name"
                          fluid
                          search
                          selection
                          options={bankOptions}
                          onChange={(e, { value, text }) => {
                            const selectedBank = bankOptions.find((bank) => bank.value === value);
                            if (selectedBank) {
                              setBankID(value);
                              setBankName(selectedBank.text);
                            }
                          }}
                          required
                          defaultValue={bankOptions[0].value}
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Bank Account No *</label>
                        <Input
                          placeholder="Bank Account No"
                          onChange={(e) => setBankAccountNo(e.target.value)}
                          required
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Bank Account Type *</label>
                        <Dropdown
                          placeholder="Bank Account Type"
                          fluid
                          selection
                          options={accountTypeOptions}
                          onChange={handleBankAccountTypeChange}
                          required
                          defaultValue={accountTypeOptions[0].value}
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Mandate Verification Through *</label>
                        <Dropdown
                          placeholder="Mandate Verification Through"
                          fluid
                          selection
                          options={mandateVerificationOptions}
                          onChange={handleMandateVerificationChange}
                          required
                          defaultValue={mandateVerificationOptions[0].value}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Country *</label>
                        <Input placeholder="Country" value={country} onChange={handleCountryChange} required />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Postal Address *</label>
                        <Input placeholder="Postal Address" value={postalAddress} onChange={handlePostalAddressChange} required />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Email *</label>
                        <Input
                          placeholder="Email"
                          type="email"
                          pattern={new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\.[a-zA-Z]{2,})?$")}

                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Phone Number *</label>
                        <Input
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          required
                        />
                      </Form.Field>
                    </Form.Group>

                    <Button type="submit" color={selectedGiftType === 'ONE_TIME_GIFT' ? 'green' : 'purple'} style={{ margin: 'auto', display: 'block' }}>Send Mandate Registration Link To My Phone Number</Button>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column>
                  {/* Old Form for One Time Gift */}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Enter Gift Amount (INR)*</label>
                        <Input
                          placeholder="Enter Gift Amount"
                          type="number"
                          min="1"
                          value={giftAmount}
                          onChange={handleGiftAmountChange}
                          required
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Name *</label>
                        <Input placeholder="Name" value={name} onChange={handleNameChange} required />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Country *</label>
                        <Input placeholder="Country" value={country} onChange={handleCountryChange} required />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Postal Address *</label>
                        <Input placeholder="Postal Address" value={postalAddress} onChange={handlePostalAddressChange} required />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={8} inline>
                        <label>Email *</label>
                        <Input
                          placeholder="Email"
                          type="email"
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </Form.Field>
                      <Form.Field width={8} inline>
                        <label>Phone Number *</label>
                        <Input
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          required
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field>

                        <Input type='hidden' name='merchantID' value={"P_31593"} />
                      </Form.Field>
                      <Form.Field>

                        <Input type='hidden' name='merchantTxnNo' value={merchantTxnNo} />
                      </Form.Field>
                      <Form.Field>

                        <Input type='hidden' name='payType' value={"0"} />
                      </Form.Field>
                      <Form.Field>

                        <Input type='hidden' name='currencyCode' value={"356"} />
                      </Form.Field>
                      <Form.Field>

                        <Input type='hidden' name='returnURL' value={returnURL} />
                      </Form.Field>
                      <Form.Field>

                        <Input type='hidden' name='secureHash' value={securehashing} />
                      </Form.Field>
                    </Form.Group>
                    <Button color={selectedGiftType === 'ONE_TIME_GIFT' ? 'green' : 'purple'} type="submit" style={{ margin: 'auto', display: 'block' }}>Pay Now</Button>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <form method="post" id="myForm" name="myForm" action="https://secure-ptg.payphi.com/pg/api/sale?v=2" encType="application/x-www-form-urlencoded">
            <input type="hidden" id="merchantID" name="merchantID" value="P_31593" />
            <input type="hidden" id="merchantTxnNo" ref={txnref} name="merchantTxnNo" />
            <input type="hidden" id="datetimeTxn" name="datetimeTxn" value={new Date().toISOString()} />
            <input type="hidden" name="payType" value="0" />
            <input type="hidden" name="returnURL" value={returnURL} />
            <input type="hidden" name="secureHash" ref={securedref} id="secureHash" />
            <input type="hidden" name="currencyCode" value="356" />
            <input type="hidden" name="amount" ref={amountref} />
            <input type="hidden" name="customerEmailID" value={email} />
            <input type="hidden" name="pay_now" value="PayNow" />
            {/* <input type="hidden" name="pay_now" value="PayNow" /> */}
            <button id="submitter" name="submit" ref={submitButtonRef} type="submit" hidden>Submit</button>
          </form>
        </Container>
      </>

  );
};

export default DonationTest;
