import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Button, Segment, Image } from 'semantic-ui-react';

const Topbar = () => {
    const sitedocsPath = '/sitedocs/'; // The path to your sitedocs folder
    const fileName = 'org.png';

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const buttonGroupStyle = {
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'stretch',
        justifyContent: isSmallScreen ? 'flex-start' : 'center', // Center for large screens
    };

    const buttonStyle = {
        marginBottom: isSmallScreen ? '5px' : '0',
        marginRight: isSmallScreen ? '0' : '5px',
        // Add other styles as needed
    };

    return (
        <div>
            {/* Small header section on top right with buttons */}
            <Segment inverted textAlign="right" style={{ position: 'relative' }}>
                <br></br>
                <Container style={{ textAlign: 'center' }}>
                    <Image src={process.env.PUBLIC_URL + '/sitedocs/org.png'} style={{ width: '100%' }} />
                    <Header as="h2" inverted>
                        IIPE VISAKHAPATNAM
                    </Header>
                    <div style={buttonGroupStyle}>
                        <Button as={Link} to="/" color="blue" style={buttonStyle}>
                            Donate Now
                        </Button>
                        <Button as={Link} to="https://www.iipe.ac.in/giving/" color="blue" style={buttonStyle}>
                            Giving Portal
                        </Button>
                        <Button as={Link} to="https://www.iipe.ac.in/contact_us/" color="blue" style={buttonStyle}>
                            Contact Us
                        </Button>
                        <Button as={Link} to="https://www.iipe.ac.in/" color="blue" style={buttonStyle}>
                            Website
                        </Button>
                        <Button as={Link} to="https://iipe.ac.in/donation/docs/10AC.pdf" color="blue" style={buttonStyle}>
                            Form 10AC
                        </Button>
                    </div>
                </Container>
            </Segment>
            <br />
            {/* Your main content goes here */}
        </div>
    );
};

export default Topbar;
