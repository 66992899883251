import React, { useEffect, useState } from 'react';
import { Container, Grid, Header, Card, Icon, Dimmer, Loader, Table } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { iipeerpapi } from '../misc/iipeerpapi';
import datetimeutils from '../utils/DateTimeUtils';
import Topbar from './Topbar'
  

const PaymentStatus = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { NewmerchantTxnNo } = props
  // Retrieve query parameters
//  const merchantTxnNo = queryParams.get('merchantTxnNo');

  // const { NewmerchantTxnNo } = location.state || {};
  
  // console.log("payment"+NewmerchantTxnNo);
  const merchantTxnNo = NewmerchantTxnNo;
  // console.log("otg1"+NewmerchantTxnNo);
  // console.log("otg2"+merchantTxnNo);
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await iipeerpapi.getonetimepaymentstatus(merchantTxnNo);
        setApiData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false, whether the request succeeds or fails
      }
    };

    getData();
  }, [merchantTxnNo]);



  // Render payment details in a Semantic UI React table
  const renderPaymentDetails = () => (
    <Table celled striped compact textAlign="center" style={{ width: '50%', margin: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">Payment Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell>{apiData.oneTimeGift!=null?apiData.oneTimeGift.name:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email</Table.Cell>
          <Table.Cell>{apiData.oneTimeGift!=null?apiData.oneTimeGift.email:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Phone Number</Table.Cell>
          <Table.Cell>{apiData.oneTimeGift!=null?apiData.oneTimeGift.phoneNumber:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Amount (INR)</Table.Cell>
          <Table.Cell>{apiData.oneTimeGift!=null?apiData.oneTimeGift.amount:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Transaction Status</Table.Cell>
          <Table.Cell>{apiData.oneTimeResponse!=null?apiData.oneTimeResponse.jsonResponse.respDescription:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>IIPE Transaction Number</Table.Cell>
          <Table.Cell>{apiData.oneTimeResponse!=null?apiData.oneTimeResponse.jsonResponse.merchantTxnNo:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Gateway Transaction Number</Table.Cell>
          <Table.Cell>{(!apiData.oneTimeResponse!=null&&apiData.oneTimeResponse.jsonResponse!=null &&
          apiData.oneTimeResponse.jsonResponse.txnID!=null)?apiData.oneTimeResponse.jsonResponse.txnID:'NA'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Transaction Date & Time</Table.Cell>
          <Table.Cell>{(apiData.oneTimeResponse!=null&&apiData.oneTimeResponse.jsonResponse.paymentDateTime!=null)?datetimeutils.formatDateTimeToDateTime(convertToIST(apiData.oneTimeResponse.jsonResponse.paymentDateTime)) + ' (IST)':'NA'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>

  );


  function convertToIST(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Months are 0-based in JavaScript Date objects
    const day = dateString.substring(6, 8);
    const hours = dateString.substring(8, 10);
    const minutes = dateString.substring(10, 12);
    const seconds = dateString.substring(12, 14);

    const utcDateObject = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
    utcDateObject.setHours(utcDateObject.getHours() - 5);
    utcDateObject.setMinutes(utcDateObject.getMinutes() - 30);
    // Convert to IST
    const istOptions = { timeZone: "Asia/Kolkata" };
    const istDateString = utcDateObject.toLocaleString("en-US", istOptions);
    console.log(istDateString)
    return istDateString;
  }

  return (
    <>
    <Topbar />
    
    <Container style={{ marginTop: '20px', 'min-height': '75vh' }}>
      {loading ? (
        // Display spinner while loading
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      ) : apiData && apiData.oneTimeResponse && apiData.oneTimeResponse && (apiData.oneTimeResponse.jsonResponse.responseCode === '000'||
      apiData.oneTimeResponse.jsonResponse.responseCode === '0000' )? (
        // Display success content for response code 000
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={6}>
              <Card fluid color="green">
                <Card.Content textAlign="center">
                  <Icon name="check circle" size="huge" color="green" />
                  <Header as="h2" style={{ fontSize: '2em' }}>
                    Donation is Successful!
                  </Header>
                  <Card.Description style={{ fontSize: '1.2em' }}>
                    Your donation to the Indian Institute of Petroleum and Energy is successful!
                    <br />
                    <br />
                    {/* <a href="https://iipe.ac.in/giving">Go To Giving Portal</a> */}
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{renderPaymentDetails()}</Grid.Column>
          </Grid.Row>
        </Grid>
      ) : apiData && apiData.oneTimeGift ? (
        // Display failed content for response codes other than 000
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={6}>
              <Card fluid color="red">
                <Card.Content textAlign="center">
                  <Icon name="exclamation circle" size="huge" color="red" />
                  <Header as="h2" style={{ fontSize: '2em' }}>
                    Donation is Unsuccessful!
                  </Header>
                  <Card.Description style={{ fontSize: '1.2em' }}>
                    {' Your donation to the Indian Institute of Petroleum and Energy is failed!'}
                    <br />
                    <br /> 
                    <a href="/">Please Go to Donation Page To Try Again!</a>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{renderPaymentDetails()}</Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        // Display a message if no data is available
<Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={6}>
        <Card fluid color="green">
          <Card.Content textAlign="center">
            <Icon name="exclamation circle" size="huge" color="red" />
            <Header as="h2" style={{ fontSize: '2em' }}>
              Data Unavailable
            </Header>
            <Card.Description style={{ fontSize: '1.2em' }}>
              Data Is Not Available In Our Records For The Given Merchant ID
              <br />
              <br />
              <a href="/">Please Launch The Donation Portal To Donate!</a>
            </Card.Description>
          </Card.Content>
        </Card>
        </Grid.Column>
        </Grid.Row>
        </Grid>
      )}
    </Container>

    </>
  );
};

export default PaymentStatus;
