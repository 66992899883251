import axios from 'axios'
import { config } from '../../Constants'
import { parseJwt } from './Helpers'

export const iipeerpapi = {
  authenticate,
  signup,
  numberOfUsers,
  numberOfOrders,
  getUsers,
  getLeaves,
  deleteUser,
  getOrders,
  deleteOrder,
  createOrder,
  getUserMe,
  getHolidays,
  getLeaveBalance,
  getLeavesHistory,
  getFullUserDetails,
  updateApproval,
  updateDelegation,
  applyLeave,
  cancelLeave,
  applySLLeave,
  submitJoiningForm,
  getApprovalLeaves,
  getLeavesforAdmin,
  getDelegatedLeaves,
  deleteLeaves,
  fileUpload,
  fileDownload,
  changepassword,
  leavejoiningform,
  leavejoiningformsforapprover,
  getleavejoiningforms,
  getleavejoiningform,
  deletejoiningform,
  leavejoiningApprovals,
  leaveChain,
  submitfeedback,
  getfeedback,
  submitonetimegift,
  submitrecurringgift,
  getonetimepaymentstatus,
  getrecurring
}

function submitJoiningForm(user, payLoad) {
  return instance.post('/', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function authenticate(username, password) {
  return instance.post('/auth/authenticate', { username, password }, {
    headers: { 'Content-type': 'application/json' }
  })
}

function fileUpload(user, formData) {
  return instance.post('/file/upload', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': bearerAuth(user)
    }
  })
}

function fileDownload(user, filename) {
  return instance.get(`/file/download/${filename}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': bearerAuth(user),
    },
    responseType: 'blob'
  })
}

function applyLeave(user, leavePayload) {
  return instance.post('/api/leaves/applyleave', leavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function cancelLeave(user , cancelLeavePayload) {
  return instance.put(`/api/leaves/cancelleave`, cancelLeavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function applySLLeave(user, leavePayload) {
  return instance.post('/api/leaves/applystationleave', leavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function changepassword(user, payLoad) {
  return instance.post('/api/users/changepassword', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function submitfeedback(user, payLoad) {
  return instance.post('/api/users/feedback', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function submitonetimegift( payLoad) {
  return instance.post('/api/donations/onetimegift', payLoad, {
    
  })
}

function submitrecurringgift( payLoad) {
  return instance.post('/api/donations/recurringgift', payLoad, {  
  })
}

function getfeedback(user) {
  return instance.get('/api/users/getfeedback', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getonetimepaymentstatus(merchantTxnNo) {
  return instance.get(`/api/donations/getonetimepaymentstatus/${merchantTxnNo}`, {
  })
}

function getrecurring(merchantTxnNo) {
  return instance.get(`/api/donations/getrecurring/${merchantTxnNo}`, {
  })
}

function leavejoiningformsforapprover(user) {
  return instance.get('api/leaves/leavejoiningformsforapprover', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leavejoiningform(user, payLoad) {
  return instance.post('/api/leaves/leavejoiningform', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleavejoiningforms(user) {
  return instance.get('/api/leaves/getleavejoiningforms', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleavejoiningform(user, leavejoiningformid) {
  return instance.get(`/api/leaves/leavejoiningform/${leavejoiningformid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function deletejoiningform(user, leavejoiningformid) {
  return instance.delete(`/api/leaves/deletejoiningform/${leavejoiningformid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leaveChain(user, leaveID) {
  return instance.get(`api/leaves/leavechain/${leaveID}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function signup(user) {
  return instance.post('/auth/signup', user, {
    headers: { 'Content-type': 'application/json' }
  })
}

function numberOfUsers() {
  return instance.get('/public/numberOfUsers')
}

function numberOfOrders() {
  return instance.get('/public/numberOfOrders')
}

function getUsers(user, username) {
  const url = username ? `/api/users/${username}` : '/api/users/allusers'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function deleteUser(user, username) {
  return instance.delete(`/api/users/${username}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getOrders(user, text) {
  const url = text ? `/api/orders?text=${text}` : '/api/orders'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getHolidays() {
  const url = '/api/holidays'
  return instance.get(url, {
  })
}

function getLeaveBalance(user) {
  const url = 'api/leaves/leavebalance'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getFullUserDetails(user) {
  const url = 'api/users/newfullme'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeavesHistory(user) {
  const url = '/api/leaves/newleaves'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getDelegatedLeaves(user) {
  const url = '/api/leaves/delegatedleaves'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getApprovalLeaves(user) {
  const url = '/api/leaves/leavesforapprover'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeavesforAdmin(user) {
  const url = '/api/leaves/getleavesforadmin'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeaves(user, text) {
  const url = `api/users/leaves`
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function deleteOrder(user, orderId) {
  return instance.delete(`/api/orders/${orderId}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function deleteLeaves(user, leaveId) {
  return instance.delete(`/api/leaves/deleteleave/${leaveId}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function updateApproval(user, payload, userId) {
  return instance.put(`/api/leaves/updateapproval/${userId}`, payload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leavejoiningApprovals(user, payLoad, joinApprovalId) {
  return instance.put(`/api/leaves/leavejoiningapproval/${joinApprovalId}`, payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function updateDelegation(user, payload, userId) {
  return instance.put(`/api/leaves/updatedelegation/${userId}`, payload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}
function createOrder(user, order) {
  return instance.post('/api/orders', order, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(user)
    }
  })
}

function getUserMe(user) {
  return instance.get('/api/users/fullme', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.request.use(function (config) {
  // If token is expired, redirect user to login
  if (config.headers.Authorization) {
    const token = config.headers.Authorization.split(' ')[1]
    const data = parseJwt(token)
    if (Date.now() > data.exp * 1000) {
      window.location.href = "/login"
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// -- Helper functions

function bearerAuth(user) {
  return `Bearer ${user.accessToken}`
}