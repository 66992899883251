import { format ,parseISO,padStart} from 'date-fns';
import { enUS } from 'date-fns/locale';

const datetimeutils = {
    formatDateToDate,
    formatDateToTime,
    formatDateTimeToDateTime,
    formatDateTimeToDate,
    formatDateTimeToTime
}


function formatDateToDate(datestring)  {
    if (datestring == null) {
      return "NA"
    }
    const date = new Date(datestring);
    const formattedDate = format(date, 'dd-MMMM-yyyy');
    return formattedDate
}

function formatDateToTime(datestring)  {
    if (datestring == null) {
      return "NA"
    }
    const date = new Date(datestring);
    const timeString = format(date, 'hh:mm a');
    return timeString
}

function formatDateTimeToDateTime(datestring)  {
    if (datestring == null) {
      return "NA"
    }
    const date = new Date(datestring);

    const timeString = format(date, 'hh:mm a');
    const formattedDate = format(date, 'dd-MMMM-yyyy');
    return formattedDate+' '+timeString;
}

function formatDateTimeToDate(datestring)  {
  if (datestring == null) {
    return "NA"
  }
  const date = new Date(datestring);
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const formattedDate = format(date, "dd-MMMM-yyyy", { locale: enUS });
console.log('formattedDate');
console.log(formattedDate);
  // console.log(datestring)
  
  // const dateObject = parseISO(datestring);
  // //console.log(dateObject)
  // const formattedDate = format(dateObject, "dd-MMMM-yyyy hh:mm a", { locale: enUS });
  return formattedDate;
}

function formatDateTimeToTime(datestring)  {
  if (datestring == null) {
    return "NA"
  }

  const date = new Date(datestring);
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const formattedTime = format(date, "hh:mm a", { locale: enUS });
console.log('formattedTime');
console.log(formattedTime);
  return formattedTime;
}



  // Format the Date object into the desired output format
  //const formattedDate = format(dateObject, "dd-MMMM-yyyy hh:mm a");

export default datetimeutils;